









































































































import { Component, Prop, Watch } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import { UserManagementUser } from "@/models/user";
import accessControl from "@/api/access_control";
import { Account } from "@/interfaces/Account";
import UserOverviewEmployee from "@/components/usermanagement/employees/UserOverviewEmployee.vue";
import AccountFilter from "@/components/form-components/search/AccountFilter.vue";
import { mixins } from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import TheHeader from "@/components/TheHeader.vue";
import Page from "@/components/layouts/Page.vue";
import { accountLabel } from "@/utils/accountDisplayFormatter";

@Component({
  methods: { accountLabel },
  components: {
    UserOverviewEmployee,
    Loader,
    AccountFilter,
    Page,
    TheHeader,
  },
  name: "EmployeeUserManagement",
})
@Translatable("usermanagement.employees")
export default class UserManagement extends mixins(TranslatableComponent) {
  @Prop()
  private readonly selectedAccountId: string | undefined;

  private isLoadingUsers: boolean = false;
  private isLoadingAccounts: boolean = false;

  private users: UserManagementUser[] = [];

  private accounts: Account[] = [];

  get hasAccounts(): boolean {
    return this.accounts.length > 0;
  }

  get hasUsers(): boolean {
    return this.users.length > 0;
  }

  fetchMyAccountUsers() {
    this.isLoadingUsers = true;
    accessControl.account_users
      .index(this.selectedAccountId!)
      .then((data) => {
        this.users = data.data;
      })
      .catch((error) => {
        this.$snotify.error(
          this.$i18n.t("api.loading.error", {
            model: this.$i18n.tc(UserManagementUser.modelTranslationField, 2),
          })
        );
        this.users = [];
      })
      .finally(() => {
        this.isLoadingUsers = false;
      });
  }

  mounted() {
    this.isLoadingAccounts = true;
    this.users = [];
    this.accounts = [];
    accessControl.accounts
      .accounts()
      .then((response) => {
        this.accounts = response.data;
      })
      .catch(() => {
        this.$snotify.error(
          this.$i18n.t("api.loading.error", {
            model: this.$i18n.tc(`models.accounts.model_name`, 2),
          })
        );
        this.accounts = [];
        this.users = [];
      })
      .finally(() => {
        this.isLoadingAccounts = false;
      });
    if (this.selectedAccountId) this.fetchMyAccountUsers();
  }

  private onAccountChanged(account: Account | null) {
    const newAccountId = account?.id ?? undefined;
    if (newAccountId === this.selectedAccountId) {
      return;
    }

    const currentQuery = { ...this.$route?.query };

    if (newAccountId) {
      currentQuery.selectedAccountId = newAccountId;
    } else {
      delete currentQuery.selectedAccountId;
    }

    this.$router.push({ query: currentQuery });
  }

  @Watch("selectedAccountId")
  private selectAccount() {
    this.users = [];
    if (this.selectedAccountId) this.fetchMyAccountUsers();
  }
}
